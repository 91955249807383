.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 30vh;
  left: 25%;
  width: 50%;
  height: fit-content;
  z-index: 100;
  overflow: hidden;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.95);
}

.header {
  padding: 1rem;
}

.header h2 {
  font-size: 1.5rem;
  margin: 0;
  color: black;
  text-align: center;
  font-weight: 400;
}

.content {
  padding: 1rem;
  text-align: center;
  font-weight: 400;
  font-size: 1.25rem;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

@media only screen and (max-device-width: 480px) {
  .modal {
    width: 75%;
    top: 30vh;
    left: 12.5%;
    z-index: 100;
  }

  .actions{
    justify-content: center;
  }
}