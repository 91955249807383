.button{
  margin-top: .5rem;
  height: 2.5rem;
  width: 8rem;
  border-radius: 15px;
  box-shadow: none;
  border: 0px;
  background-color: #116DFF;
  font-size: large;
  color: white;
}

.button:hover{
  background-color: #307fff;
}