.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.container {
  border: 0px;
  border-radius: 10px;
  width: 40%;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  padding: .5rem;
}

.container header {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.form {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1rem;
  padding-bottom: 4.5rem;
}

.form input {
  height: 2rem;
  width: 65%;
  background-color: transparent;
  resize: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #000000;
  font-size: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
}

.form button {
  background-color: #116DFF;
  height: 2.2rem;
  width: 7rem;
  font-size: 105%;
  border-radius: 15px;
  border: 0px;
  color: white;
}

@media only screen and (max-device-width: 480px) {
  .container {
    width: 75%;
    padding-top: 0;
    margin-top: 0;
    justify-content: center;
  }

  .form {
    margin-top: 1rem;
    padding-bottom: 2.5rem;
  }

  .form input {
    width: 75%;
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .form button {
    width: 8rem;
    font-weight: 400;
    font-size: 105%;
  }
}